import { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AuthContext = createContext();

export function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const logout = () => {
        setUser(null);
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/login');
    };

    useEffect(() => {
        const checkTokenValidity = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                logout();
                return;
            }
            try {
                // Add /api prefix to match backend routes
                await axios.get('/api/auth/validate-token', {
                    headers: { Authorization: `Bearer ${token}` }
                });
            } catch (error) {
                if (error.response?.status === 401 || error.response?.status === 403) {
                    logout();
                }
            }
        };

        // Check for stored auth data on mount
        const token = localStorage.getItem('token');
        const storedUser = localStorage.getItem('user');
        if (token && storedUser) {
            setUser(JSON.parse(storedUser));
            checkTokenValidity();
        }
        setLoading(false);

        // Set up axios interceptor for 401/403 responses
        const interceptor = axios.interceptors.response.use(
            response => response,
            error => {
                if (error.response?.status === 401 || error.response?.status === 403) {
                    logout();
                }
                return Promise.reject(error);
            }
        );

        // Set up periodic token validation (every 5 minutes)
        const tokenCheckInterval = setInterval(checkTokenValidity, 5 * 60 * 1000);

        return () => {
            axios.interceptors.response.eject(interceptor);
            clearInterval(tokenCheckInterval);
        };
    }, []);  // Remove logout from dependencies to avoid recreating effect

    const login = (userData) => {
        setUser(userData.user);
        localStorage.setItem('token', userData.token);
        localStorage.setItem('user', JSON.stringify(userData.user));
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, loading }}>
            {!loading && children}
        </AuthContext.Provider>
    );
}
export const useAuth = () => useContext(AuthContext);