import React, { useState, useEffect, useCallback, useMemo } from 'react'; // Remove useCallback
// import { QRCodeSVG } from 'qrcode.react';
import socket from '../services/socket';
// import backgroundImage from '../assets/background.png';
import { Search } from 'lucide-react';


// Gift Tracker Component
const GiftTracker = () => {

  const API_URL = process.env.REACT_APP_API_URL;
  // Combine related states
  const [trackerState, setTrackerState] = useState({
    participants: [],
    loading: true,
    error: '',
    searchTerm: '',
    selectedParticipant: null
  });

  // Memoize fetchParticipants with useCallback
  const fetchParticipants = useCallback(async () => {
    try {
      const response = await fetch(`${API_URL}/api/participants`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch participants');
      }
      
      const data = await response.json();
      setTrackerState(prev => ({ ...prev, participants: data, loading: false }));
    } catch (err) {
      setTrackerState(prev => ({ ...prev, error: err.message, loading: false }));
    }
  }, [API_URL]);

  useEffect(() => {
    fetchParticipants();
    
    // Listen for new participants
    socket.on('participantAdded', (newParticipant) => {
      setTrackerState(prev => ({
        ...prev,
        participants: [...prev.participants, newParticipant]
      }));
    });
    
    // Listen for gift status updates
    socket.on('giftStatusUpdated', (updatedParticipant) => {
      setTrackerState(prev => ({
        ...prev,
        participants: prev.participants.map(p => p._id === updatedParticipant._id ? updatedParticipant : p)
      }));
    });

    // Add listener for attendance updates
    socket.on('participantAttended', (participant) => {
      setTrackerState(prev => {
        const exists = prev.participants.some(p => p._id === participant._id);
        return {
          ...prev,
          participants: exists ? prev.participants.map(p => p._id === participant._id ? participant : p) : [...prev.participants, participant]
        };
      });
    });

    return () => {
      socket.off('participantAdded');
      socket.off('giftStatusUpdated');
      socket.off('participantAttended');
    };
  }, [fetchParticipants]); // Add fetchParticipants to dependencies

  const handleGiftReceived = async (participantId) => {
    try {
      const response = await fetch(`${API_URL}/api/participants/${participantId}/gift`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ giftReceived: true })
      });

      if (!response.ok) {
        throw new Error('Failed to update gift status');
      }

      const updatedParticipant = await response.json();
      setTrackerState(prev => ({
        ...prev,
        participants: prev.participants.map(p => p._id === participantId ? updatedParticipant : p),
        selectedParticipant: null
      }));
    } catch (err) {
      setTrackerState(prev => ({ ...prev, error: err.message }));
    }
  };

  // Memoize filtered participants
  const filteredParticipants = useMemo(() => 
    trackerState.participants.filter(p => 
      p.isAttended && !p.giftReceived && 
      (trackerState.searchTerm === '' || 
        `${p.fname} ${p.lname}`.toLowerCase().includes(trackerState.searchTerm.toLowerCase()) ||
        p.email.toLowerCase().includes(trackerState.searchTerm.toLowerCase()))
    ),
    [trackerState.participants, trackerState.searchTerm]
  );

  // Extract participant row component
  const ParticipantRow = React.memo(({ participant, isSelected, onSelect, onMarkReceived }) => (
    <tr 
      key={participant._id}
      className={`hover:bg-gray-50 ${isSelected ? 'bg-blue-50' : ''}`}
      onClick={() => onSelect(participant)}
    >
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm font-medium text-gray-900">
          {participant.fname} {participant.lname}
        </div>
        <div className="text-sm text-gray-500">
          {participant.designation}
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900">{participant.organization}</div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900">{participant.email}</div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right">
        <button
          onClick={(e) => {
            e.stopPropagation();
            onMarkReceived(participant._id);
          }}
          className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        >
          Mark Received
        </button>
      </td>
    </tr>
  ));

  if (trackerState.loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="mb-8">
        <h2 className="text-3xl font-bold mb-4 text-center">Gift Tracking</h2>
        <div className="text-gray-600 text-center mb-4">
          {filteredParticipants.length} attended participants pending gift receipt
        </div>
      </div>

      {trackerState.error && (
        <div className="mb-6 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
          {trackerState.error}
        </div>
      )}

      <div className="mb-6 relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Search className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          placeholder="Search by name or email..."
          value={trackerState.searchTerm}
          onChange={(e) => setTrackerState(prev => ({ ...prev, searchTerm: e.target.value }))}
          className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        />
      </div>

      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Organization
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Email
              </th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredParticipants.map((participant) => (
              <ParticipantRow 
                key={participant._id}
                participant={participant}
                isSelected={trackerState.selectedParticipant?._id === participant._id}
                onSelect={(p) => setTrackerState(prev => ({ ...prev, selectedParticipant: p }))}
                onMarkReceived={handleGiftReceived}
              />
            ))}
            {filteredParticipants.length === 0 && (
              <tr>
                <td colSpan="4" className="px-6 py-4 text-center text-gray-500">
                  No pending gift recipients found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default React.memo(GiftTracker);