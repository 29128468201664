import React, { useState, useCallback } from 'react';
import { Camera } from 'lucide-react';
import QRScanner from '../components/QRScanner';
import socket from '../services/socket';  // Add this import

const ScanPage = () => {
  const API_URL = process.env.REACT_APP_API_URL ;
  // Combine related states
  const [scanState, setScanState] = useState({
    showScanner: false,
    scannedData: null,
    error: '',
    verificationResult: null
  });

  // Memoize handler
  const handleScanSuccess = useCallback(async (data) => {
    try {
      console.log('Received scan data:', data);
      
      // Parse the QR code data if it's a string
      const parsedData = typeof data === 'string' ? JSON.parse(data) : data;

      if (!parsedData.id) {
        throw new Error('Invalid QR code: Missing participant ID');
      }

      const response = await fetch(`${API_URL}/api/participants/verify/${parsedData.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to verify participant');
      }

      const result = await response.json();
      console.log('API Response:', result);

      // Ensure attendanceTime is included in the participant data
      if (result.alreadyAttended && result.participant.attendanceTime) {
        result.participant = {
          ...result.participant,
          attendanceTime: new Date(result.participant.attendanceTime).toISOString()
        };
      }

      setScanState(prev => ({
        ...prev,
        scannedData: result.participant,
        verificationResult: result,
        error: ''
      }));

      // Add this: Emit socket event for attendance update
      if (!result.alreadyAttended) {
        socket.emit('participantAttended', result.participant);
      }

      return result; // Return result for success message
    } catch (err) {
      console.error('Verification Error:', err);
      setScanState(prev => ({
        ...prev,
        error: err.message,
        scannedData: null
      }));
      throw err; // Throw error to be handled by scanner
    }
  }, []);

  // Clean up component rendering
  return (
    <div className="min-h-screen bg-gray-100 p-4">
      {!scanState.showScanner ? (
        <ScannerUI 
          scanState={scanState} 
          onOpen={() => setScanState(prev => ({ ...prev, showScanner: true }))}
        />
      ) : (
        <QRScanner
          onScanSuccess={handleScanSuccess}
          onClose={() => setScanState(prev => ({ ...prev, showScanner: false }))}
        />
      )}
    </div>
  );
};

// Extract UI components
const ScannerUI = React.memo(({ scanState, onOpen }) => (
  <div className="max-w-2xl mx-auto space-y-6">
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <div className="px-6 py-4 border-b border-gray-200">
        <h2 className="text-xl font-semibold">Participant Check-in</h2>
      </div>
      <div className="p-6">
        <button
          onClick={onOpen}
          className="w-full flex items-center justify-center gap-2 bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          <Camera size={20} />
          Open Scanner
        </button>
      </div>
    </div>

    {scanState.error && (
      <div className="bg-white rounded-lg shadow-md border-l-4 border-red-500">
        <div className="p-6">
          <p className="text-red-500">{scanState.error}</p>
        </div>
      </div>
    )}

    {scanState.scannedData && (
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="px-6 py-4 border-b border-gray-200">
          <h2 className="text-xl font-semibold">Participant Details</h2>
        </div>
        <div className="p-6">
          <div className="space-y-4">
            <div>
              <p className="text-sm text-gray-500">Name</p>
              <p className="font-medium">{scanState.scannedData.fname} {scanState.scannedData.lname}</p>
            </div>
            <div>
              <p className="text-sm text-gray-500">Organization</p>
              <p className="font-medium">{scanState.scannedData.organization}</p>
            </div>
            <div>
              <p className="text-sm text-gray-500">Designation</p>
              <p className="font-medium">{scanState.scannedData.designation}</p>
            </div>
            <div>
              <p className="text-sm text-gray-500">Email</p>
              <p className="font-medium">{scanState.scannedData.email}</p>
            </div>
            <div>
              <p className="text-sm text-gray-500">Phone</p>
              <p className="font-medium">{scanState.scannedData.phone}</p>
            </div>
            <div className="pt-4">
              {scanState.verificationResult?.alreadyAttended ? (
                <div>
                  <p className="text-orange-600 font-medium">⚠️ Already Attended</p>
                  <p className="text-sm text-gray-500 mt-1">
                    Attended at: {new Date(scanState.scannedData.attendanceTime).toLocaleString()}
                  </p>
                </div>
              ) : (
                <p className="text-green-600 font-medium">✓ Participant Verified</p>
              )}
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
));

export default React.memo(ScanPage);