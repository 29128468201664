import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { SocketProvider } from './context/SocketContext';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Registration from './pages/Registration';
// import EventDay from './pages/EventDay';
import PrivateRoute from './components/PrivateRoute';
import ParticipantList from './components/ParticipantList';

function App() {
  
  return (
    <AuthProvider>
      <SocketProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/registration" element={<Registration />} />
            {/* <Route path="/event-day" element={<EventDay />} /> */}
            <Route path="/participants" element={<ParticipantList/>} />
          </Route>
        </Routes>
      </SocketProvider>
    </AuthProvider>
  );
}

export default App;