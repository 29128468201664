import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tab, TabGroup, TabList, TabPanels, TabPanel } from '@headlessui/react';
// import { FaSearch } from 'react-icons/fa';
import ParticipantList from '../components/ParticipantList';
import Registration from './Registration';
import { useAuth } from '../contexts/AuthContext';
import socket from '../services/socket';
import GiftTracker from '../components/GiftTracker';
import QRScanner from '../components/QRScanner';
import ScanPage from './ScanPage';

function Dashboard() {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    socket.on('participantUpdated', (data) => {
      setParticipants(prev => 
        prev.map(p => p._id === data._id ? data : p)
      );
    });

    return () => socket.off('participantUpdated');
  }, []);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Failed to logout:', error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Event Dashboard</h1>
        <button
          onClick={handleLogout}
          className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
        >
          Logout
        </button>
      </div>
      
      {/* <div className="flex flex-col items-center justify-center mb-8">
        <div className="relative w-full max-w-2xl">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <FaSearch className="h-8 w-8 text-gray-400" />
          </div>
          <input
            type="text"
            className="block w-full pl-14 pr-3 py-4 rounded-lg border-2 border-gray-200 
                     text-gray-900 focus:outline-none focus:border-blue-500 text-lg"
            placeholder="Search events, participants, or gifts..."
          />
        </div>
      </div> */}

      <TabGroup>
        <TabList className="flex space-x-1 rounded-xl bg-blue-900/20 p-1 mb-4">
          <Tab className={({ selected }) =>
            `w-full rounded-lg py-2.5 text-sm font-medium leading-5
             ${selected 
               ? 'bg-white text-blue-700 shadow'
               : 'text-gray-600 hover:bg-white/[0.12] hover:text-blue-600'}`
          }>
            Registration
          </Tab>
          <Tab className={({ selected }) =>
            `w-full rounded-lg py-2.5 text-sm font-medium leading-5
             ${selected 
               ? 'bg-white text-blue-700 shadow'
               : 'text-gray-600 hover:bg-white/[0.12] hover:text-blue-600'}`
          }>
            Participants
          </Tab>
          <Tab className={({ selected }) =>
            `w-full rounded-lg py-2.5 text-sm font-medium leading-5
             ${selected 
               ? 'bg-white text-blue-700 shadow'
               : 'text-gray-600 hover:bg-white/[0.12] hover:text-blue-600'}`
          }>
            Scan
          </Tab>
          <Tab className={({ selected }) =>
            `w-full rounded-lg py-2.5 text-sm font-medium leading-5
             ${selected 
               ? 'bg-white text-blue-700 shadow'
               : 'text-gray-600 hover:bg-white/[0.12] hover:text-blue-600'}`
          }>
            Gifts
          </Tab>
          <Tab className={({ selected }) =>
            `w-full rounded-lg py-2.5 text-sm font-medium leading-5
             ${selected 
               ? 'bg-white text-blue-700 shadow'
               : 'text-gray-600 hover:bg-white/[0.12] hover:text-blue-600'}`
          }>
            Settings
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Registration />
          </TabPanel>
          <TabPanel>
            <ParticipantList />
          </TabPanel>
          <TabPanel>
            {/* <QRScanner /> */}
            <ScanPage />
          </TabPanel>
          <TabPanel>
            <div className="p-4">
            <GiftTracker />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="p-4">
              <h2 className="text-xl font-semibold">Event Settings</h2>
              {/* Add settings content here */}
            </div>
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  );
}

export default Dashboard;