import React, { useState, useEffect, useCallback, useMemo } from 'react';
import socket from '../services/socket';

const ParticipantList = () => {

  const API_URL = process.env.REACT_APP_API_URL ;
  const [listState, setListState] = useState({
    participants: [],
    searchTerm: '',
    editingParticipant: null,
    isEditModalOpen: false
  });

  const fetchParticipants = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }
      
      const response = await fetch(`${API_URL}/api/participants`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch participants');
      }
      
      const data = await response.json();
      setListState(prev => ({
        ...prev,
        participants: Array.isArray(data) ? data : []
      }));
    } catch (error) {
      console.error('Error fetching participants:', error);
      setListState(prev => ({
        ...prev,
        participants: []
      }));
    }
  }, [API_URL]);

  useEffect(() => {
    fetchParticipants();
    
    // Socket event listeners for real-time updates
    socket.on('participantUpdated', (updatedParticipant) => {
      setListState(prev => ({
        ...prev,
        participants: prev.participants.map(p => p._id === updatedParticipant._id ? updatedParticipant : p)
      }));
    });

    socket.on('participantDeleted', (deletedId) => {
      setListState(prev => ({
        ...prev,
        participants: prev.participants.filter(p => p._id !== deletedId)
      }));
    });

    socket.on('participantAdded', (newParticipant) => {
      setListState(prev => ({
        ...prev,
        participants: [...prev.participants, newParticipant]
      }));
    });

    return () => {
      socket.off('participantUpdated');
      socket.off('participantDeleted');
      socket.off('participantAdded');
    };
  }, [fetchParticipants]);

  const handleEdit = useCallback((participant) => {
    setListState(prev => ({
      ...prev,
      editingParticipant: participant,
      isEditModalOpen: true
    }));
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this participant?')) {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${API_URL}/api/participants/${id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          setListState(prev => ({
            ...prev,
            participants: prev.participants.filter(p => p._id !== id)
          }));
        }
      } catch (error) {
        console.error('Error deleting participant:', error);
      }
    }
  };

  const handleUpdate = async (id, updatedData) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_URL}/api/participants/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(updatedData),
      });

      if (response.ok) {
        const updatedParticipant = await response.json();
        setListState(prev => ({
          ...prev,
          participants: prev.participants.map(p => p._id === id ? updatedParticipant : p),
          isEditModalOpen: false
        }));
      }
    } catch (error) {
      console.error('Error updating participant:', error);
    }
  };

  const filteredParticipants = useMemo(() => 
    listState.participants.filter(participant =>
      participant.fname?.toLowerCase().includes(listState.searchTerm.toLowerCase()) ||
      participant.lname?.toLowerCase().includes(listState.searchTerm.toLowerCase()) ||
      participant.email?.toLowerCase().includes(listState.searchTerm.toLowerCase()) ||
      participant.organization?.toLowerCase().includes(listState.searchTerm.toLowerCase())
    ),
    [listState.participants, listState.searchTerm]
  );

  const ParticipantRow = React.memo(({ participant, onEdit, onDelete }) => (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap">{participant.fname}</td>
      <td className="px-6 py-4 whitespace-nowrap">{participant.lname}</td>
      <td className="px-6 py-4 whitespace-nowrap">{participant.designation}</td>
      <td className="px-6 py-4 whitespace-nowrap">{participant.organization}</td>
      <td className="px-6 py-4 whitespace-nowrap">{participant.phone}</td>
      <td className="px-6 py-4 whitespace-nowrap">{participant.email}</td>
      <td className="px-6 py-4 whitespace-nowrap">
        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
          participant.isAttended 
            ? 'bg-green-100 text-green-800' 
            : 'bg-yellow-100 text-yellow-800'
        }`}>
          {participant.isAttended ? "Present" : "Not Arrived"}
        </span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
          participant.giftReceived 
            ? 'bg-green-100 text-green-800' 
            : 'bg-gray-100 text-gray-800'
        }`}>
          {participant.giftReceived ? "Received" : "Pending"}
        </span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <button
          onClick={() => onEdit(participant)}
          className="text-blue-600 hover:text-blue-900 mr-2"
        >
          Edit
        </button>
        <button
          onClick={() => onDelete(participant._id)}
          className="text-red-600 hover:text-red-900"
        >
          Delete
        </button>
      </td>
    </tr>
  ));

  return (
    <div className="w-full p-4 bg-white rounded-lg shadow">
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search participants..."
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={listState.searchTerm}
          onChange={(e) => setListState(prev => ({ ...prev, searchTerm: e.target.value }))}
        />
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">First Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Designation</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Organization</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Phone</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Attendance</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Gift Status</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {filteredParticipants.map((participant) => (
              <ParticipantRow
                key={participant._id}
                participant={participant}
                onEdit={handleEdit}
                onDelete={handleDelete}
              />
            ))}
            {filteredParticipants.length === 0 && (
              <tr>
                <td colSpan={9} className="px-6 py-4 text-center text-gray-500">
                  No participants found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="mt-4 text-sm text-gray-500">
        Total Participants: {listState.participants.length}
      </div>

      {/* Edit Modal */}
      {listState.isEditModalOpen && listState.editingParticipant && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl mb-4">Edit Participant</h2>
            <form onSubmit={(e) => {
              e.preventDefault();
              handleUpdate(listState.editingParticipant._id, {
                fname: e.target.fname.value,
                lname: e.target.lname.value,
                designation: e.target.designation.value,
                organization: e.target.organization.value,
                phone: e.target.phone.value,
                email: e.target.email.value,
              });
            }}>
              <div className="mb-3">
                <label htmlFor="fname" className="block text-sm font-medium text-gray-700 mb-1">First Name</label>
                <input
                  id="fname"
                  name="fname"
                  placeholder="First Name"
                  defaultValue={listState.editingParticipant.fname}
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="lname" className="block text-sm font-medium text-gray-700 mb-1">Last Name</label>
                <input
                  id="lname"
                  name="lname"
                  placeholder="Last Name"
                  defaultValue={listState.editingParticipant.lname}
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="designation" className="block text-sm font-medium text-gray-700 mb-1">Designation</label>
                <input
                  id="designation"
                  name="designation"
                  placeholder="Designation"
                  defaultValue={listState.editingParticipant.designation}
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="organization" className="block text-sm font-medium text-gray-700 mb-1">Organization</label>
                <input
                  id="organization"
                  name="organization"
                  placeholder="Organization"
                  defaultValue={listState.editingParticipant.organization}
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">Phone</label>
                <input
                  id="phone"
                  name="phone"
                  placeholder="Phone"
                  defaultValue={listState.editingParticipant.phone}
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email</label>
                <input
                  id="email"
                  name="email"
                  placeholder="Email"
                  defaultValue={listState.editingParticipant.email}
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
              <div className="flex justify-end gap-2">
                <button
                  type="button"
                  onClick={() => setListState(prev => ({ ...prev, isEditModalOpen: false }))}
                  className="px-4 py-2 bg-gray-200 rounded"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(ParticipantList);