import io from 'socket.io-client';

const SOCKET_URL = process.env.REACT_APP_API_URL ;

const socket = io(SOCKET_URL, {
  cert: process.env.NODE_ENV === 'production' ? process.env.SSL_CERT : '',
  key: process.env.NODE_ENV === 'production' ? process.env.SSL_KEY : '',
  path: '/socket',
  // withCredentials: true,
  transports: ['websocket', 'polling'],
  // secure: true,
  // rejectUnauthorized: false, // Only if using self-signed certificate
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 1000,
});

socket.on('connect', () => {
  console.log('Connected to Socket.IO server');
});

socket.on('connect_error', (error) => {
  console.error('Socket.IO connection error:', error);
});

export default socket;
