import React, { useState, useRef, useEffect } from 'react';
import { BrowserMultiFormatReader } from '@zxing/library';
import { Camera, X, Loader } from 'lucide-react';

const QRScanner = ({ onScanSuccess, onClose }) => {
  const videoRef = useRef(null);
  const [error, setError] = useState('');
  const [isScanning, setIsScanning] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successName, setSuccessName] = useState('');
  const codeReaderRef = useRef(null);  // Add this line to store codeReader reference
  const [isRepeatVisit, setIsRepeatVisit] = useState(false);
  const [attendanceTime, setAttendanceTime] = useState(null);

  useEffect(() => {
    const codeReader = new BrowserMultiFormatReader();
    codeReaderRef.current = codeReader;  // Store reference
    let mounted = true;

    const startScanning = async () => {
      try {
        setError('');
        setIsScanning(true);
        const videoInputDevices = await codeReader.listVideoInputDevices();
        const selectedDeviceId = videoInputDevices[0].deviceId;
        
        await codeReader.decodeFromVideoDevice(
          selectedDeviceId,
          videoRef.current,
          async (result, err) => {
            if (result && mounted && !showSuccess) {  // Only process if not showing success
              try {
                // Get the raw QR data
                const encodedData = result.getText();
                console.log('Raw QR data:', encodedData);

                // Remove any whitespace from the encoded data
                const cleanedData = encodedData.trim();
                console.log('Cleaned data:', cleanedData);
                
                // Decode base64 string
                const decodedString = atob(cleanedData);
                console.log('Decoded string:', decodedString);

                // Parse the JSON data
                const parsedData = JSON.parse(decodedString);
                console.log('Parsed data:', parsedData);

                if (!parsedData.id) {
                  throw new Error('Invalid QR code: Missing participant ID');
                }

                const response = await onScanSuccess(parsedData);
                setSuccessName(`${response.participant.fname} ${response.participant.lname}`);
                setIsRepeatVisit(response.alreadyAttended);
                setAttendanceTime(response.participant.attendanceTime);
                setShowSuccess(true);
                
                // Pause scanning
                codeReader.reset();
                
                // Reset success message after 5 seconds and restart scanning
                setTimeout(() => {
                  setShowSuccess(false);
                  if (mounted) {
                    startScanning();
                  }
                }, 5000);  // Increased to 5000ms (5 seconds)

              } catch (parseError) {
                console.error('Parse Error:', parseError);
                setError('Invalid QR code format. Please try scanning again.');
              }
            }
            if (err && err.name !== 'NotFoundException') {
              console.error('Scanning Error:', err);
              setError('Error scanning QR code');
            }
          }
        );
      } catch (err) {
        console.error('Camera Error:', err);
        setError('Failed to access camera. Please ensure camera permissions are granted.');
        setIsScanning(false);
      }
    };

    if (mounted && !showSuccess) {  // Only start if not showing success
      startScanning();
    }

    return () => {
      mounted = false;
      codeReader.reset();
    };
  }, [onScanSuccess, showSuccess]);  // Add showSuccess to dependencies

  return (
    <div className="fixed inset-0 bg-black flex flex-col items-center">
      <div className="relative w-full h-full">
        <video
          ref={videoRef}
          className="w-full h-full object-cover"
        />
        
        {showSuccess ? (
          <div className="absolute inset-0 bg-black/90 flex flex-col items-center justify-center text-center p-4">
            <h1 className="text-6xl font-bold text-green-500 mb-6">
              {isRepeatVisit ? 'Already Attended!' : 'Welcome!'}
            </h1>
            <p className="text-4xl text-white mb-8 font-semibold">{successName}</p>
            {isRepeatVisit && attendanceTime && (
              <p className="text-xl text-orange-400 mb-8">
                Previous check-in: {new Date(attendanceTime).toLocaleString()}
              </p>
            )}
            <Loader className="animate-spin text-white" size={50} />
            <p className="text-xl text-white mt-8">Ready to scan next participant...</p>
          </div>
        ) : (
          <div className="absolute inset-0 flex flex-col items-center justify-center">
            <div className="w-64 h-64 border-2 border-white rounded-lg relative">
              <div className="absolute inset-0 border-2 border-white opacity-50" />
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-48 h-1 bg-red-500 opacity-50" />
            </div>
            
            <div className="absolute bottom-20 left-0 right-0 text-center text-white px-4">
              <p className="text-lg mb-2">Position the QR code within the frame</p>
              <p className="text-sm opacity-75">The scan will happen automatically</p>
            </div>
          </div>
        )}

        {error && (
          <div className="absolute top-4 left-4 right-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
            {error}
          </div>
        )}

        <button 
          onClick={onClose}
          className="absolute top-4 right-4 p-2 text-white hover:text-gray-300"
        >
          <X size={24} />
        </button>

        {!isScanning && (
          <div className="absolute inset-0 bg-black/75 flex items-center justify-center">
            <button
              onClick={() => setIsScanning(true)}
              className="flex items-center gap-2 bg-white text-black px-6 py-3 rounded-lg hover:bg-gray-100"
            >
              <Camera size={24} />
              <span>Start Scanning</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default QRScanner;