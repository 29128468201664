import React,{ useState, useCallback, useMemo } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import html2canvas from 'html2canvas';
// import socket from '../services/socket';
import { useSocket } from '../context/SocketContext';
import backgroundImage from '../assets/background.png';

const Registration = () => {
  const API_URL = process.env.REACT_APP_API_URL ;
  const { socket, isConnected } = useSocket();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    designation: '',
    organization: '',
    phone: '',
    email: ''
  });
  
  const [status, setStatus] = useState({
    loading: false,
    error: '',
    success: false,
    qrCode: ''
  });

  const handleChange = useCallback((field) => (e) => {
    setFormData(prev => ({ ...prev, [field]: e.target.value }));
  }, []);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setStatus(prev => ({ ...prev, loading: true, error: '' }));
    
    const emptyFields = Object.entries(formData)
      .filter(([_, value]) => !value.trim())
      .map(([key]) => key);
    
    if (emptyFields.length > 0) {
      setStatus(prev => ({ ...prev, loading: false, error: `Please fill in all required fields: ${emptyFields.join(', ')}` }));
      return;
    }

    try {
      const apiData = {
        fname: formData.firstName,
        lname: formData.lastName,
        designation: formData.designation,
        organization: formData.organization,
        phone: formData.phone,
        email: formData.email
      };

      const response = await fetch(`${API_URL}/api/participants/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(apiData)
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Registration failed');
      }

      const qrData = btoa(JSON.stringify({
        id: data.participant._id,
        email: data.participant.email
      }));
      
      setStatus(prev => ({ ...prev, success: true, qrCode: qrData }));
      if (isConnected) {
        socket.emit('newParticipant', data.participant);
      }

      // Reset form
      setFormData({
        firstName: '',
        lastName: '',
        designation: '',
        organization: '',
        phone: '',
        email: ''
      });

    } catch (err) {
      setStatus(prev => ({ ...prev, error: err.message }));
    } finally {
      setStatus(prev => ({ ...prev, loading: false }));
    }
  }, [formData, API_URL]);

  const handleDownloadQR = () => {
    const qrElement = document.getElementById('qr-container');
    html2canvas(qrElement).then(canvas => {
      const image = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = image;
      link.download = `qr-code-${formData.firstName}-${formData.lastName}.png`;
      link.click();
    });
  };

  const QRCodeDisplay = useMemo(() => {
    if (!status.qrCode) return null;
    return (
      <div id="qr-container" className="relative w-[500px] h-[748px]">
        <img 
          src={backgroundImage} 
          alt="QR Background" 
          className="absolute top-0 left-0 w-full h-full object-contain"
        />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <QRCodeSVG value={status.qrCode} size={200} />
        </div>
      </div>
    );
  }, [status.qrCode]);

  return (
    <div className="max-w-3xl mx-auto p-6">
      <h2 className="text-3xl font-bold mb-8 text-center">Register New Participant</h2>

      {status.error && (
        <div className="mb-6 p-4 bg-red-100 border border-red-400 text-red-700 rounded" role="alert">
          {status.error}
        </div>
      )}
      
      {status.success && (
        <div className="mb-6 p-4 bg-green-100 border border-green-400 text-green-700 rounded" role="alert">
          Registration successful!
        </div>
      )}

      <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 after:content-['*'] after:ml-0.5 after:text-red-500">
            First Name
          </label>
          <input
            value={formData.firstName}
            onChange={handleChange('firstName')}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Enter first name"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 after:content-['*'] after:ml-0.5 after:text-red-500">
            Last Name
          </label>
          <input
            value={formData.lastName}
            onChange={handleChange('lastName')}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Enter last name"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 after:content-['*'] after:ml-0.5 after:text-red-500">
            Designation
          </label>
          <input
            value={formData.designation}
            onChange={handleChange('designation')}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Enter designation"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 after:content-['*'] after:ml-0.5 after:text-red-500">
            Organization
          </label>
          <input
            value={formData.organization}
            onChange={handleChange('organization')}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Enter organization name"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 after:content-['*'] after:ml-0.5 after:text-red-500">
            Phone Number
          </label>
          <input
            type="tel"
            value={formData.phone}
            onChange={handleChange('phone')}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Enter phone number"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 after:content-['*'] after:ml-0.5 after:text-red-500">
            Email
          </label>
          <input
            type="email"
            value={formData.email}
            onChange={handleChange('email')}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Enter email address"
            required
          />
        </div>

        <div className="md:col-span-2">
          <button
            type="submit"
            disabled={status.loading}
            className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white 
              ${status.loading 
                ? 'bg-gray-400 cursor-not-allowed' 
                : 'bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500'
              }`}
          >
            {status.loading ? 'Registering...' : 'Register Participant'}
          </button>
        </div>
      </form>

      {QRCodeDisplay && (
        <div className="mt-8 p-6 bg-white rounded-lg shadow-md">
          <div className="flex flex-col items-center">
            <h3 className="text-xl font-semibold mb-4">Participant QR Code</h3>
            {QRCodeDisplay}
            <button
              onClick={handleDownloadQR}
              className="mt-4 px-4 py-2 bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600 text-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
            >
              Download QR Code
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(Registration);
